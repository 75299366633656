.tab-container {
  .bg-gray {
    background-color: #333333;
  }

  .tab-header {
    background-color: #333333;
    color: #fff;
    padding: 1vw 2vw;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-top-left-radius: 2vw;
    border-top-right-radius: 2vw;
    font-weight: bold;
    font-size: 16px;
    position: relative;

    &.active {
      background-color: #191919;
    }

    &.collapsed {
      margin-block-end: 1.5px;
    }
  }

  .tab-body-radius {
    border-radius: 0 2vw 2vw 2vw;
  }
}
