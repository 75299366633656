.map-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.mapboxgl-control-container {
  display: none;
}

.custom-marker {
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
}

.marker-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.marker-btn img {
  width: 20px;
  height: 20px;
}

.mapboxgl-canvas {
  // margin-left: 10rem;
  // width: 1300px;
}

.explore-section {
  display: flex;
  flex-direction: column;
  position: absolute;
  padding-top: 2.3vw;
  padding-left: 2vw;
  width: 20%;
  gap: 1vw;
  z-index: 100000;
}

.map-layer {
  position: rever-layer;
  right: 0;
}
