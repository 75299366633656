.select-button-container {
  .p-selectbutton .p-button.p-highlight {
    background-color: #191919;
    color: #fff;
  }

  .p-button:hover {
    background: transparent;
  }
  .p-selectbutton .p-button {
    color: #000;
    border: none;
    border-radius: 41px;
    font-size: 2vw;
    font-weight: lighter;
    font-family: 'Helvetica Now Display', sans-serif;
    padding: 1vw;
    width: 15vw;
    height: 3.3vw;
    text-transform: uppercase;
  }
}
