body {
  box-sizing: border-box;
  line-height: 25.2px;
}

#root {
  font-family: 'Poppins', sans-serif !important;
  margin: 0;
  padding: 0;
  border: 0;
  height: 100%;
}

.poppins-regular {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.bg-dark {
  background-color: #191919;
}
.text-dark {
  color: #191919;
}

.poppins-bold {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-style: normal;
}

// .page-container {
//   display: flex;
//   flex-direction: column;
//   gap: 3vh;
// }

.card-border-radius {
  border-radius: 2.5vw;
}

.card-border-radius-sm {
  border-radius: 31px;
}

.card-border-radius-xs {
  border-radius: 28px;
}

.helve-font {
  font-family: 'Helvetica Now Display', sans-serif;
}

html,
body {
  height: 100%;
  background-color: white;
  margin: 0;
  line-height: 2.5vh;
}
