.tableContainer {
  width: 100%;
  color: #fff;
  border-radius: 8px;
  /* Add overflow styling to make sure scrollbar appears when necessary */
  overflow-y: auto;
  // max-height: 400px; /* Adjust the height as needed */

  /* Include scrollbar styles here */
  ::-webkit-scrollbar {
    width: 8px;
  }
  /* Hide the native scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  /* Hide scrollbar for WebKit-based browsers (Chrome, Safari, etc.) */
  &::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-track {
    background: #2c2c2c;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 20px;
    border: 2px solid #2c2c2c;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #777;
  }

  scrollbar-width: thin;
  scrollbar-color: #555 #2c2c2c;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
}

.tableWrapper {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 12px;
  }

  th {
    font-weight: bold;
  }

  tbody tr {
    background-color: #2c2c2c !important;

    &:nth-child(even) {
      background-color: #1c1c1c !important;
    }
  }

  input[type='checkbox'] {
    transform: scale(1.2);
    cursor: not-allowed;
  }
}
