.p-checkbox.p-highlight .p-checkbox-box {
  background-color: white;
  border: none;
  color: black;
}

.total-species {
  width: 100%;
  padding: 1.3vw 2.4vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.map-details-wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50%;
}
