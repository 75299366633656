@import '../../assets/styles/styles-variables.scss';

.card {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Border radius mixin for each corner
@mixin border-radius-corner($corner, $radius) {
  border-#{$corner}-radius: $radius;
}

// Define classes for different border-radius sizes and corners
@each $size,
  $radius
    in (
      'small': $small-border-radius,
      'medium': $medium-border-radius,
      'large': $large-border-radius
    )
{
  .card-border-top-left-#{$size} {
    @include border-radius-corner(top-left, $radius);
  }
  .card-border-top-right-#{$size} {
    @include border-radius-corner(top-right, $radius);
  }
  .card-border-bottom-left-#{$size} {
    @include border-radius-corner(bottom-left, $radius);
  }
  .card-border-bottom-right-#{$size} {
    @include border-radius-corner(bottom-right, $radius);
  }
}

// Define background color classes
@each $name,
  $color
    in (
      'card-bg-darkest': $color-black-darkest,
      'card-bg-dark': $color-black-dark,
      'card-bg-gray-opacity-dark': $color-gray-dark,
      'card-bg-gray-dark': $color-gray-dark,
      'card-bg-gray-medium': $color-gray-medium,
      'card-bg-gray-light': $color-gray-light,
      'card-bg-white': $color-white,
      'card-bg-green': $color-green
    )
{
  .#{$name} {
    background-color: $color;
  }
}
