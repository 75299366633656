.map-tab-wrapper {
  height: 100%;
  border-radius: inherit;
}

.map-tab {
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 1;
}
