@import '../../assets/styles/styles-variables.scss';

.p-radiobutton.p-highlight .p-radiobutton-box {
  background-color: $color-black-darkest;
  border-color: $color-black-darkest;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: $color-white;
}

.p-paginator-current {
  color: $color-black-dark;
  padding: 0;
}

.p-paginator-next {
  color: $color-black-dark;
  min-width: 0px;
  font-weight: lighter;
}

.p-paginator-prev {
  color: $color-black-dark;
  min-width: 0px;
}
