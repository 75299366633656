@import '../../assets/styles/styles-variables.scss';

@mixin text-field-variant(
  $class,
  $bgColor,
  $valueColor,
  $placeholderColor,
  $fontSize
) {
  .#{$class} {
    background-color: $bgColor;
    color: $valueColor;
    font-size: $fontSize;
    border: none;
    height: 100%;
    width: 100%;
    padding: 0vw 2vw;

    &::placeholder {
      color: $placeholderColor;
      font-size: $fontSize;
      font-weight: lighter;
    }
  }
}

@mixin border-radius($class, $borderRadius) {
  .#{$class} {
    border-radius: $borderRadius;
  }
}

@include border-radius('rounded', $large-border-radius);

@include border-radius('semi-rounded', $small-border-radius);

@include text-field-variant(
  'dark-text-field',
  $color-gray-dark,
  $color-white,
  $color-white,
  $font-size-body1
);

@include text-field-variant(
  'light-text-field',
  $color-gray-light,
  $color-gray-dark,
  $color-gray-opacity-dark,
  $font-size-body1
);

.text-field {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.text-field-icon {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
}
