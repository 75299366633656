@import '../../assets/styles/styles-variables.scss';

.website-header {
  width: 100%;
  display: flex;
  padding: 45px;
  height: 120px;

  .logo {
    width: 242px;
    height: 72px;
    display: flex;
    align-items: center;
    gap: 18px;

    span {
      color: rgba(35, 31, 32, 0.25);
      font-size: 32px;
    }
  }

  .sub-logo {
    width: 103px;
    height: 103px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 800px) {
  .website-header {
    margin-inline: 35px;

    .logo {
      width: 180px;
      height: 45px;

      span {
        font-size: 28px;
      }
    }

    .sub-logo {
      width: 75px;
      height: 75px;
    }
  }
}

.p-sidebar-header {
  padding: 1vw 2vw;
}

.sidebar {
  width: 40%;
  border-top-left-radius: 1.6vw;
  border-bottom-left-radius: 1.6vw;

  .icon {
    background-color: $color-black-dark;
  }
}

.review-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2vw;
  height: 100%;

  .camera-icon {
    top: 77%;
    right: 15%;
    position: absolute;
  }
}
