// colors variables
$color-black-darkest: #0f0f0f;
$color-black-dark: #191919;
$color-gray-opacity-light: #7777;
$color-gray-opacity-dark: #19191980;
$color-gray-dark: #333333;
$color-gray-medium: #e7e7e7;
$color-gray-light: #f2f2f2;
$color-white: #ffffff;
$color-red: #ff003d;
$color-orange: #ff9900;
$color-yellow: #faff00;
$color-green: #45f974;

//border radius variables
$small-border-radius: 0.5vw;
$medium-border-radius: 1.6vw;
$large-border-radius: 2.1vw;

// font sizes
$font-size-h1: 6.5vw;
$font-size-h2: 2.6vw;
$font-size-h3: 2.1vw;
$font-size-h4: 1.6vw;
$font-size-h5: 1vw;
$font-size-h6: 1vw;
$font-size-body1: 1vw;
$font-size-subtitle2: 0.8vw;

// font weights
$font-weight-xblack: 950;
$font-weight-regular: 400;

// Define font family variables
$font-family-helvetica: 'Helvetica Now Display', sans-serif;
$font-family-poppins: 'Poppins', sans-serif;

// Create a mixin for background colors
@mixin bg-color($class, $color) {
  .bg-#{$class} {
    background-color: $color;
  }
}

// Create a mixin for text colors
@mixin text-color($class, $color) {
  .text-#{$class} {
    color: $color;
  }
}

// Use the mixin to generate text color classes
@each $name,
  $color
    in (
      'black-darkest': $color-black-darkest,
      'black-dark': $color-black-dark,
      'gray-opacity-dark': $color-gray-opacity-dark,
      'gray-dark': $color-gray-dark,
      'gray-medium': $color-gray-medium,
      'gray-light': $color-gray-light,
      'white': $color-white,
      'red': $color-red,
      'orange': $color-orange,
      'yellow': $color-yellow,
      'green': $color-green
    )
{
  @include bg-color($name, $color);
  @include text-color($name, $color);
}

// Create a mixin for typography sizes
@mixin typography-size($class, $size) {
  .#{$class} {
    font-size: $size;
  }
}

// Use the mixin to generate typography size classes
@each $name,
  $size
    in (
      'h1': $font-size-h1,
      'h2': $font-size-h2,
      'h3': $font-size-h3,
      'h4': $font-size-h4,
      'h5': $font-size-h5,
      'h6': $font-size-h6,
      'body1': $font-size-body1,
      'subtitle2': $font-size-subtitle2
    )
{
  @include typography-size($name, $size);
}
