.report-table-header-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 6vh;
  position: relative;
  margin: 0 auto;
  z-index: 10;
}

.dropdown-container {
  flex: 0 0 17%;
  position: relative;
  margin-right: 1rem;
  height: 100%;
}

.textfield-container {
  flex: 1;
  margin-right: 1rem;
  height: 100%;
}

.download-btn {
  flex: 0 0 20%;
  height: 100%;
}
