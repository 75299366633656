@import '../../../assets/styles/styles-variables.scss';

.inputSwitchWrapper {
  display: flex;
  gap: 0.5vw;
  align-items: center;
  justify-content: center;

  .customSlider {
    background: $color-gray-opacity-light;
  }
}
:global {
  .p-inputswitch.p-highlight .p-inputswitch-slider {
    background: $color-green;
  }
  .p-inputswitch.p-highlight .p-inputswitch-slider::before {
    left: 0.2vw;
  }
  .p-inputswitch .p-inputswitch-slider:before {
    background: $color-black-dark;
    left: 0.1vw;
  }
}
