.p-radiobutton.p-highlight .p-radiobutton-box {
  background-color: black;
  border-color: black;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: white;
}

.p-paginator-current {
  color: #191919;
  padding: 0;
}

.p-paginator-next {
  color: #191919;
  min-width: 0px;
  font-weight: lighter;
}

.p-paginator-prev {
  color: #191919;
  min-width: 0px;
}

.risk-table-header {
  display: flex;
  align-items: center;
  width: 90%;
  height: 6vh;
  position: relative;
  margin: 0 auto;
}

.risk-dropdwon {
  flex: 0 0 20%;
  position: relative;
  margin-right: 1rem;
  height: 100%;
}

.risk-textfield {
  flex: 1;
  margin-right: 1rem;
  height: 100%;
}

.risk-download-list-btn {
  flex: 0 0 20%;
  height: 100%;
}
