.navbar {
  & ~ div {
    max-height: 3.3vw;
  }
}

.navbar-wrapper {
  height: 3.8vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navlink-item {
  display: flex;
  align-items: center;
  text-decoration: none;
}
