.p-radiobutton.p-highlight .p-radiobutton-box {
  background-color: black;
  border-color: black;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: white;
}

.p-paginator-current {
  color: #191919;
  padding: 0;
}

.p-paginator-next {
  color: #191919;
  min-width: 0px;
  font-weight: lighter;
}

.p-paginator-prev {
  color: #191919;
  min-width: 0px;
}

.location-table-header {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  gap: 1rem;
  align-items: center;
  height: 3vw;
}

.grid-column-span-5 {
  grid-column: span 5;
}

.grid-column-span-8 {
  grid-column: span 8;
}

.grid-column-span-4 {
  grid-column: span 4;
}

.grid-column-span-3 {
  grid-column: span 3;
}

.add-location-wrapper {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
