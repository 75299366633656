@import '../../../../assets/styles/styles-variables.scss';

.minimizeSoilContainer {
  padding: 2vw 1vw 2vw 0;
  color: $color-white;
  max-height: 57.031vw;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: $color-black-dark;

  /* Faded End Effect */
  box-shadow: inset 0px -20px 20px -20px rgba(0, 0, 0, 0.85);
}

.titleSection {
  max-width: 55%;
  @media (max-width: 1024px) {
    max-width: 49%;
  }
  @media (min-width: 1920px) {
    max-width: 60%;
  }
}

.tasksWrapper {
  display: flex;
  flex-direction: column;
  padding: 0 2vw 0 0;

  gap: 2vw;
  overflow-y: scroll;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $color-gray-opacity-light;
    border-radius: 10px;
    opacity: 0;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $color-gray-opacity-light;
    opacity: 1;
  }
}

.taskCard {
  padding: 1vw 0 0 3.5vw;
  border-radius: 8px;
  display: flex;
}
