.location-details {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.grid-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  height: 83vh;
  width: 100%;
}

.summary-column {
  width: 23%;
  height: 100%;
}

.map-column {
  width: 77%;
  height: 100%;
}

.profile-risk {
  width: 100%;
}
