@import '../../assets/styles/styles-variables.scss';

$x-small-circle: 0.45vw;
$small-circle: 1vw;
$large-circle: 4vw;

@mixin circle-size($class, $size) {
  .#{$class} {
    width: $size;
    height: $size;
    border-radius: 50%;
  }
}

@mixin circle-color($class, $color) {
  .#{$class} {
    background-color: $color;
  }
}

@each $name,
  $size
    in (
      'x-small-circle': $x-small-circle,
      'small-circle': $small-circle,
      'large-circle': $large-circle
    )
{
  @include circle-size($name, $size);
}

@each $name,
  $color
    in (
      'red-circle': $color-red,
      'orange-circle': $color-orange,
      'yellow-circle': $color-yellow,
      'gray-circle': $color-gray-medium,
      'dark-gray-circle': $color-gray-opacity-light,
      'green-circle': $color-green
    )
{
  @include circle-color($name, $color);
}
