@import '../../assets/styles/styles-variables.scss';

@mixin hover-effect($hover-bg-color) {
  &:hover {
    background-color: $hover-bg-color;
    cursor: pointer;
  }
}

.divider {
  border-top: 1px solid $color-black-dark;
  margin: 0.4vw 0;
  width: 85%;
}

.dropdown-item {
  &.hoverable {
    @include hover-effect($color-gray-light);
  }

  &.active {
    background-color: $color-gray-dark;
    color: $color-white;
  }

  &:not(.active) {
    &.hoverable {
      @include hover-effect($color-gray-medium);
    }
  }
}
