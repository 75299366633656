@import '../../assets/styles/styles-variables.scss';

@mixin typography-weight($class, $weight) {
  .#{$class} {
    font-weight: $weight;
  }
}

//  a mixin for typography colors
@mixin typography-color($class, $color) {
  .#{$class} {
    color: $color;
  }
}

// Use the mixin to generate typography color classes
@each $name,
  $color
    in (
      'black-darkest': $color-black-darkest,
      'black-dark': $color-black-dark,
      'gray-dark': $color-gray-dark,
      'black-dark-opacity': $color-gray-opacity-dark,
      'gray-medium': $color-gray-medium,
      'gray-light': $color-gray-light,
      'white': $color-white,
      'red': $color-red,
      'orange': $color-orange,
      'yellow': $color-yellow,
      'green': $color-green
    )
{
  @include typography-color($name, $color);
}

// Apply extra black weight to all headings
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: $font-weight-xblack;
  text-transform: uppercase;
}

// Apply regular weight to body1 and subtitle2
.body1,
.subtitle2 {
  font-weight: $font-weight-regular;
  text-transform: capitalize;
}

// Apply extra black weight to all headings
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: $font-family-helvetica;
}

// Apply regular weight to body1 and subtitle2
.body1,
.subtitle2,
.h6 {
  font-weight: $font-family-poppins;
}
