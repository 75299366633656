@import '../../assets/styles/styles-variables.scss';

@mixin icon-button-variant($class, $bgColor, $fontColor) {
  .#{$class} {
    background-color: $bgColor;
    color: $fontColor;
    border: none;
    padding: 0.5vw;
    border-radius: 50%;
    cursor: pointer;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@mixin icon-button-size($class, $fontClass) {
  .#{$class} {
    @extend #{$fontClass};
  }
}

@include icon-button-variant(
  'dark-icon-button',
  $color-black-dark,
  $color-white
);

@include icon-button-variant(
  'light-icon-button',
  $color-gray-medium,
  $color-black-dark
);

@each $name, $fontSize in ('small-button': '.body1', 'large-button': '.h4') {
  @include icon-button-size($name, $fontSize);
}
