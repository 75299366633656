// .dashboard-row {
//   height: 390px;
//   padding: 40px;
//   color: #ffff;
// }

.dashboard-row:last-child {
  height: 550px;
  padding-top: 0;
}

.location-status {
  background-color: #222222;
  // border-radius: 40px;
  // display: flex;
  // padding: 25px;
  // overflow: hidden;
}

.loc-count {
  // padding: 54px;
  // height: 265px;
  // border-radius: 31px;
  background-color: #444444;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // margin-right: 25px;
  // h3 {
  //   font-size: 80px;
  //   margin-block: 50px;
  // }
  // span {
  //   font-size: 15px;
  // }
}

.right-side {
  h3 {
    text-transform: uppercase;
    font-size: 40px;
    line-height: 40px;
    margin-block: 20px;
  }

  p {
    font-size: 20px;
    line-height: 24px;
  }
}

.chart-container {
  background-color: #f2f2f2;
  border-radius: 40px;
  height: 315px;
}

// .action-btns {
//   display: flex;
//   flex-direction: column;
//   justify-content: space-around;
//   .action-btn {
//     display: flex;
//     justify-content: center;
//     text-transform: uppercase;
//     width: 100%;
//     margin-inline: auto;
//     background-color: black;
//     color: #ffff;
//     border-radius: 50px;
//     height: 65px;
//     border: 0;
//   }
// }

.profile-graph {
  color: black;

  header {
    align-items: center;
    margin-bottom: 30px;

    h3 {
      font-size: 30px;
      text-transform: uppercase;
    }

    .rank {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 3px;

      .icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 5px;
        border-radius: 50%;
      }

      .text {
        font-size: 15px;
      }
    }

    .rank:nth-of-type(1) .icon {
      background-color: #ff003d;
    }

    .rank:nth-of-type(2) .icon {
      background-color: #ff9900;
    }

    .rank:nth-of-type(3) .icon {
      background-color: #faff00;
    }

    .rank:nth-of-type(4) .icon {
      background-color: #45f974;
    }
  }
}

.rows-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .row.grid {
    align-items: center;
  }

  span {
    font-size: 15px;
    line-height: 18px;
    text-transform: capitalize;
  }

  div.col-9 {
    .circle-contianer {
      color: black;

      .circle {
        width: 75px;
        height: 75px;
        // padding-top: 20%;
        border-radius: 50%;
        // background: red;
      }
    }
  }
}

.area-text-contianer {
  p {
    width: 75px;
    text-align: center;
  }
}

.h-30vh {
  height: 30vh;
}

.explore-section-wrapper {
  max-height: 50vh;
}

.grid-nogutter-gap {
  gap: 0.8vw;
}

.dashboard-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}
