.summary-wrapper {
  height: 100%;
  padding: 0.5vw;
  display: flex;
  flex-direction: column;

  .serach-icon {
    font-size: 1.4vw;
    padding: 1vw;
  }

  .serach-input {
    border: none;
    background-image: linear-gradient(to right, white, #191919);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.5vw;
    font-weight: bolder;
  }
}
