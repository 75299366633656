@import '../../../../assets/styles/styles-variables.scss';

.container {
  padding: 2vw 1vw 2vw 0;
  color: $color-white;
  max-height: 57.031vw;
  max-width: 40%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  /* Faded End Effect */
  box-shadow: inset 0px -20px 20px -20px rgba(0, 0, 0, 0.85);
}

.filters {
  display: flex;
  align-items: center;
  gap: 1.95vw;
  padding-inline-start: 1.4vw;
  @media (max-width: 1768px) {
    padding-inline-start: 1.7vw;
  }
}

.tasksWrapper {
  padding: 0 2vw 0 0;
  cursor: pointer;
  overflow-y: scroll;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $color-gray-opacity-light;
    border-radius: 10px;
    opacity: 0;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $color-gray-opacity-light;
    opacity: 1;
  }
}

.selectedTask {
  background: #fff;
  border-radius: 0 27px 27px 0;
}
.taskItem {
  padding: 1.2vw 0 1vw 2.5vw;
  display: flex;
  flex-direction: column;
}

.taskItem:not(:last-child) {
  border-bottom: 1px solid;
  border-color: var(--color-white);
}
.subtitle {
  color: rgba(255, 255, 255, 0.4);
  padding: 0.9vw 0;
}
.subtitleSelected {
  color: rgba(34, 34, 34, 0.4);
}
