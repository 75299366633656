.p-checkbox.p-highlight .p-checkbox-box {
  background-color: white;
  border: none;
  color: black;
}

.actions-details {
  min-height: 100%;
  width: 40%;
  display: flex;
  flex-direction: column;
}
