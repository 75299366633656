@import '../../assets/styles/styles-variables.scss';

@mixin button-variant($class, $bgColor, $fontColor, $extra: ()) {
  .#{$class} {
    background-color: $bgColor;
    color: $fontColor;
    border: none;
    border-radius: $large-border-radius;
    cursor: pointer;
    height: 100%;
    width: 100%;

    // Additional styles if provided
    @if $extra != null {
      @each $property, $value in $extra {
        #{$property}: $value;
      }
    }

    // Disabled state
    &:disabled {
      background-color: lighten($bgColor, 20%);
      color: lighten($fontColor, 40%);
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
}

@mixin button-size($class, $fontClass) {
  .#{$class} {
    @extend #{$fontClass};
  }
}

// Button Variants
@include button-variant('dark-button', $color-black-dark, $color-white);

@include button-variant('light-button', $color-white, $color-black-dark);

@include button-variant(
  'text-light-button',
  transparent,
  $color-black-dark,
  (
    text-decoration: underline,
  )
);

@include button-variant(
  'text-dark-button',
  transparent,
  $color-white,
  (
    text-decoration: underline,
  )
);

@include button-variant('transparent-button', transparent, $color-black-dark);

// Button Sizes
@each $name, $fontSize in ('small-button': '.body1', 'large-button': '.h4') {
  @include button-size($name, $fontSize);
}
